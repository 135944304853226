import { CartIconOOI } from '@wix/pro-gallery-artstore-statics';
import './artstore-cart-icon.scss';
import '../ArtStore/FullscreenWrapper/artstore-pages.scss';
import { withStyles } from '@wix/native-components-infra';

export default {
  component: withStyles(CartIconOOI, {
    cssPath: ['cart.min.css'],
    strictMode: false,
  }),
};
